 import axios from '@/axios.js'
 import store from '@/modules/Shared/Store/store'

const getResortsInfo = async () => {
  try {
     return await Promise.all(
      await store.getters['auth/drm'].resorts.map( async function(resort){
        let response = await axios.publicapi.get(`/resorts/v3/${resort.uuid}/info`)
        let inforesort = response.data.object;
        inforesort.uuid = resort.uuid;
        return inforesort;
      })
    ).then(resortsinfo => {
      return resortsinfo;
    });
  } catch (error) {
    throw new Error(error)
  }
}
const getOsmToken = async (uuid) => {
  try{
    return await axios.editorapi.get(`/openstreetmaps/v3/resort/${uuid}/token`);
  } catch (error) {
    throw new Error(error)
  }
}

const generateRtdata = async (uuid) => {
  try{
    await axios.editorapi.post(`/resorts/v3/${uuid}/rtdata`);
  } catch (error) {
    throw new Error(error)
  }
}

 const generatePois = async (uuid) => {
   try{
     await axios.editorapi.post(`/resorts/v3/${uuid}/pois`);
   } catch (error) {
     throw new Error(error)
   }
 }


export default {
  getResortsInfo,
  getOsmToken,
  generateRtdata,
  generatePois
}


